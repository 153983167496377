@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  @apply text-stone-700;
}

.rdp-vhidden {
  @apply hidden;
}

.required:after {
  content: ' *';
  color: red;
}

.streched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: '';
  background-color: rgba(0, 0, 0, 0);
}

:host(.nextjs-toast-errors-parent) {
  display: none !important;
}

:-webkit-scrollbar {
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 15px;
  height: 15px;
}

::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 9999px;
  border: 3px solid transparent;
  background-clip: padding-box;
}

::-webkit-scrollbar-thumb:hover {
  background: hsl(var(--primary-darker));
  border-radius: 9999px;
  border: 3px solid transparent;
  background-clip: padding-box;
}

::-webkit-scrollbar-corner {
  background: #f5f5f4;
}

::-webkit-scrollbar-track {
  background: transparent;
}

body:hover::-webkit-scrollbar-thumb {
  background: hsl(var(--border));
  border-radius: 9999px;
  border: 4px solid transparent;
  background-clip: padding-box;
}

body.scrollable {
  overflow-y: scroll;
}

@layer base {
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground min-h-screen;
    font-feature-settings: 'rlig' 1, 'calt' 1;
  }

  :root {
    --blue-choice: hsl(226 71% 40%);

    --background: 0 0% 100%;
    --foreground: 30 6% 25%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 25 5.3% 44.7%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --primary: 25 95% 53%;
    --primary-darker: 21, 90%, 48%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --ring: var(--primary);

    --radius: 0.5rem;

    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;

    --chart-expired: 12 76% 61%;
    --chart-completed: 173 58% 39%;

    --chart-estimated: 211 100% 50%;
    --chart-statistical-measure: 134 61% 41%;
    --chart-trendline: 354 70% 54%;

    --chart-trendline-variant: 12 76% 61%;
    --chart-metric-variant: 173 52% 40%;
    --chart-estimated-variant: 47 11% 49%;

    --rounded-input: 12px;
    --rounded-button: 12px;
    --rounded-dialog: 12px;

    --sidebar-background: 60 5% 96%;
    --sidebar-foreground: 240 5.3% 26.1%;
    --sidebar-primary: 240 5.9% 10%;
    --sidebar-primary-foreground: 0 0% 98%;
    --sidebar-accent: 24 5.7% 82.9%;
    --sidebar-accent-foreground: 240 5.9% 10%;
    --sidebar-border: 220 13% 91%;
    --sidebar-ring: 217.2 91.2% 59.8%;
  }
}

.theme_zinc {
  --sidebar-background: 60 5% 96%;
  --sidebar-foreground: 240 5.3% 26.1%;
  --sidebar-primary: 240 5.9% 10%;
  --sidebar-primary-foreground: 0 0% 98%;
  --sidebar-accent: var(--accent);
  --sidebar-accent-foreground: var(--accent-foreground);
  --sidebar-border: var(--border);
  --sidebar-ring: var(--ring);

  --background: 0 0% 100%;
  --foreground: 30 6% 25%;
  --card: 0 0% 100%;
  --card-foreground: 240 10% 3.9%;
  --popover: 0 0% 100%;
  --popover-foreground: 240 10% 3.9%;
  --primary: 240 5.9% 10%;
  --primary-foreground: 0 0% 98%;
  --secondary: 240 4.8% 95.9%;
  --secondary-foreground: 240 5.9% 10%;
  --muted: 240 4.8% 95.9%;
  --muted-foreground: 240 3.8% 46.1%;
  --accent: 240 4.8% 95.9%;
  --accent-foreground: 240 5.9% 10%;
  --destructive: 0 84.2% 60.2%;
  --destructive-foreground: 0 0% 98%;
  --border: 240 5.9% 90%;
  --input: 240 5.9% 90%;
  --ring: 240 5.9% 10%;
  --radius: 0.5rem;
  --chart-1: 12 76% 61%;
  --chart-2: 173 58% 39%;
  --chart-3: 197 37% 24%;
  --chart-4: 43 74% 66%;
  --chart-5: 27 87% 67%;
}

.theme_red {
  --sidebar-background: 60 5% 96%;
  --sidebar-foreground: 240 5.3% 26.1%;
  --sidebar-primary: 240 5.9% 10%;
  --sidebar-primary-foreground: 0 0% 98%;
  --sidebar-accent: var(--accent);
  --sidebar-accent-foreground: var(--accent-foreground);
  --sidebar-border: var(--border);
  --sidebar-ring: var(--ring);

  --background: 0 100% 100%;
  --foreground: 30 6% 25%;
  --card: 0 50% 100%;
  --card-foreground: 0 5% 15%;
  --popover: 0 100% 100%;
  --popover-foreground: 0 100% 10%;
  --primary: 0 84% 60%;
  --primary-darker: 0 84% 50%;
  --primary-foreground: 0 0% 100%;
  --secondary: 0 30% 90%;
  --secondary-foreground: 0 0% 0%;
  --muted: -38 30% 95%;
  --muted-foreground: 0 5% 40%;
  --accent: -38 30% 90%;
  --accent-foreground: 0 5% 15%;
  --destructive: 0 100% 50%;
  --destructive-foreground: 0 5% 100%;
  --border: 0 30% 82%;
  --input: 0 30% 50%;
  --ring: 0 84% 60%;
  --radius: 0.5rem;

  --sidebar-accent: -38 30% 90%;
  --sidebar-accent-foreground: 0 5% 15%;
  --sidebar-border: 220 13% 91%;
}

.theme_rose {
  --sidebar-background: 60 5% 96%;
  --sidebar-foreground: 240 5.3% 26.1%;
  --sidebar-primary: 240 5.9% 10%;
  --sidebar-primary-foreground: 0 0% 98%;
  --sidebar-accent: var(--accent);
  --sidebar-accent-foreground: var(--accent-foreground);
  --sidebar-border: var(--border);
  --sidebar-ring: var(--ring);

  --background: 0 0% 100%;
  --foreground: 30 6% 25%;
  --card: 0 0% 100%;
  --card-foreground: 240 10% 3.9%;
  --popover: 0 0% 100%;
  --popover-foreground: 240 10% 3.9%;
  --primary: 346.8 77.2% 49.8%;
  --primary-darker: 346.8 77.2% 40%;
  --primary-foreground: 355.7 100% 97.3%;
  --secondary: 240 4.8% 95.9%;
  --secondary-foreground: 240 5.9% 10%;
  --muted: 240 4.8% 95.9%;
  --muted-foreground: 240 3.8% 46.1%;
  --accent: 240 4.8% 95.9%;
  --accent-foreground: 240 5.9% 10%;
  --destructive: 0 84.2% 60.2%;
  --destructive-foreground: 0 0% 98%;
  --border: 240 5.9% 90%;
  --input: 240 5.9% 90%;
  --ring: 346.8 77.2% 49.8%;
  --radius: 0.5rem;
  --chart-1: 12 76% 61%;
  --chart-2: 173 58% 39%;
  --chart-3: 197 37% 24%;
  --chart-4: 43 74% 66%;
  --chart-5: 27 87% 67%;
}

.theme_orange {
  --sidebar-background: 60 5% 96%;
  --sidebar-foreground: 240 5.3% 26.1%;
  --sidebar-primary: 240 5.9% 10%;
  --sidebar-primary-foreground: 0 0% 98%;
  --sidebar-accent: var(--accent);
  --sidebar-accent-foreground: var(--accent-foreground);
  --sidebar-border: var(--border);
  --sidebar-ring: var(--ring);

  --background: 25 100% 100%;
  --foreground: 30 6% 25%;
  --card: 25 50% 100%;
  --card-foreground: 25 5% 15%;
  --popover: 25 100% 100%;
  --popover-foreground: 25 100% 10%;
  --primary: 25 95% 53.1%;
  --primary-darker: 25 95% 43.1%;
  --primary-foreground: 0 0% 100%;
  --secondary: 25 30% 90%;
  --secondary-foreground: 0 0% 0%;
  --muted: -13 30% 95%;
  --muted-foreground: 25 5% 40%;
  --accent: -13 30% 90%;
  --accent-foreground: 25 5% 15%;
  --destructive: 0 100% 50%;
  --destructive-foreground: 25 5% 100%;
  --border: 25 30% 82%;
  --input: 25 30% 50%;
  --ring: 25 95% 53.1%;
  --radius: 0.5rem;
}

.theme_green {
  --sidebar-background: 60 5% 96%;
  --sidebar-foreground: 240 5.3% 26.1%;
  --sidebar-primary: 240 5.9% 10%;
  --sidebar-primary-foreground: 0 0% 98%;
  --sidebar-accent: var(--accent);
  --sidebar-accent-foreground: var(--accent-foreground);
  --sidebar-border: var(--border);
  --sidebar-ring: var(--ring);

  --background: 0 0% 100%;
  --foreground: 30 6% 25%;
  --card: 0 0% 100%;
  --card-foreground: 240 10% 3.9%;
  --popover: 0 0% 100%;
  --popover-foreground: 240 10% 3.9%;
  --primary: 142.1 76.2% 36.3%;
  --primary-darker: 142.1 76.2% 26.3%;
  --primary-foreground: 355.7 100% 97.3%;
  --secondary: 240 4.8% 95.9%;
  --secondary-foreground: 240 5.9% 10%;
  --muted: 240 4.8% 95.9%;
  --muted-foreground: 240 3.8% 46.1%;
  --accent: 240 4.8% 95.9%;
  --accent-foreground: 240 5.9% 10%;
  --destructive: 0 84.2% 60.2%;
  --destructive-foreground: 0 0% 98%;
  --border: 240 5.9% 90%;
  --input: 240 5.9% 90%;
  --ring: 142.1 76.2% 36.3%;
  --radius: 0.5rem;
  --chart-1: 12 76% 61%;
  --chart-2: 173 58% 39%;
  --chart-3: 197 37% 24%;
  --chart-4: 43 74% 66%;
  --chart-5: 27 87% 67%;
}

.theme_amber {
  --sidebar-background: 60 5% 96%;
  --sidebar-foreground: 240 5.3% 26.1%;
  --sidebar-primary: 240 5.9% 10%;
  --sidebar-primary-foreground: 0 0% 98%;
  --sidebar-accent: var(--accent);
  --sidebar-accent-foreground: var(--accent-foreground);
  --sidebar-border: var(--border);
  --sidebar-ring: var(--ring);

  --background: 38 100% 100%;
  --foreground: 30 6% 25%;
  --card: 38 50% 100%;
  --card-foreground: 38 5% 15%;
  --popover: 38 100% 100%;
  --popover-foreground: 38 100% 10%;
  --primary: 38 92.1% 50.2%;
  --primary-darker: 38 92.1% 40.2%;
  --primary-foreground: 0 0% 100%;
  --secondary: 38 30% 90%;
  --secondary-foreground: 0 0% 0%;
  --muted: 0 30% 95%;
  --muted-foreground: 38 5% 40%;
  --accent: 0 30% 90%;
  --accent-foreground: 38 5% 15%;
  --destructive: 0 100% 50%;
  --destructive-foreground: 38 5% 100%;
  --border: 38 30% 82%;
  --input: 38 30% 50%;
  --ring: 38 92.1% 50.2%;
  --radius: 0.5rem;
}

.theme_yellow {
  --sidebar-background: 60 5% 96%;
  --sidebar-foreground: 240 5.3% 26.1%;
  --sidebar-primary: 240 5.9% 10%;
  --sidebar-primary-foreground: 0 0% 98%;
  --sidebar-accent: var(--accent);
  --sidebar-accent-foreground: var(--accent-foreground);
  --sidebar-border: var(--border);
  --sidebar-ring: var(--ring);

  --background: 45 100% 100%;
  --foreground: 30 6% 25%;
  --card: 45 50% 100%;
  --card-foreground: 45 5% 15%;
  --popover: 45 100% 100%;
  --popover-foreground: 45 100% 10%;
  --primary: 45 93.4% 47.5%;
  --primary-darker: 45 93.4% 37.5%;
  --primary-foreground: 0 0% 100%;
  --secondary: 45 30% 90%;
  --secondary-foreground: 0 0% 0%;
  --muted: 7 30% 95%;
  --muted-foreground: 45 5% 40%;
  --accent: 7 30% 90%;
  --accent-foreground: 45 5% 15%;
  --destructive: 0 100% 50%;
  --destructive-foreground: 45 5% 100%;
  --border: 45 30% 82%;
  --input: 45 30% 50%;
  --ring: 45 93.4% 47.5%;
  --radius: 0.5rem;
}

.theme_lime {
  --sidebar-background: 60 5% 96%;
  --sidebar-foreground: 240 5.3% 26.1%;
  --sidebar-primary: 240 5.9% 10%;
  --sidebar-primary-foreground: 0 0% 98%;
  --sidebar-accent: var(--accent);
  --sidebar-accent-foreground: var(--accent-foreground);
  --sidebar-border: var(--border);
  --sidebar-ring: var(--ring);

  --background: 84 100% 100%;
  --foreground: 30 6% 25%;
  --card: 84 50% 100%;
  --card-foreground: 84 5% 15%;
  --popover: 84 100% 100%;
  --popover-foreground: 84 100% 10%;
  --primary: 84 80.5% 44.3%;
  --primary-darker: 84 80.5% 34.3%;
  --primary-foreground: 0 0% 100%;
  --secondary: 84 30% 90%;
  --secondary-foreground: 0 0% 0%;
  --muted: 46 30% 95%;
  --muted-foreground: 84 5% 40%;
  --accent: 46 30% 90%;
  --accent-foreground: 84 5% 15%;
  --destructive: 0 100% 50%;
  --destructive-foreground: 84 5% 100%;
  --border: 84 30% 82%;
  --input: 84 30% 50%;
  --ring: 84 80.5% 44.3%;
  --radius: 0.5rem;
}

.theme_green {
  --sidebar-background: 60 5% 96%;
  --sidebar-foreground: 240 5.3% 26.1%;
  --sidebar-primary: 240 5.9% 10%;
  --sidebar-primary-foreground: 0 0% 98%;
  --sidebar-accent: var(--accent);
  --sidebar-accent-foreground: var(--accent-foreground);
  --sidebar-border: var(--border);
  --sidebar-ring: var(--ring);

  --background: 142 100% 100%;
  --foreground: 30 6% 25%;
  --card: 142 50% 100%;
  --card-foreground: 142 5% 15%;
  --popover: 142 100% 100%;
  --popover-foreground: 142 100% 10%;
  --primary: 142 70.6% 45.3%;
  --primary-darker: 142 70.6% 35.3%;
  --primary-foreground: 0 0% 100%;
  --secondary: 142 30% 90%;
  --secondary-foreground: 0 0% 0%;
  --muted: 104 30% 95%;
  --muted-foreground: 142 5% 40%;
  --accent: 104 30% 90%;
  --accent-foreground: 142 5% 15%;
  --destructive: 0 100% 50%;
  --destructive-foreground: 142 5% 100%;
  --border: 142 30% 82%;
  --input: 142 30% 50%;
  --ring: 142 70.6% 45.3%;
  --radius: 0.5rem;
}

.theme_emerald {
  --sidebar-background: 60 5% 96%;
  --sidebar-foreground: 240 5.3% 26.1%;
  --sidebar-primary: 240 5.9% 10%;
  --sidebar-primary-foreground: 0 0% 98%;
  --sidebar-accent: var(--accent);
  --sidebar-accent-foreground: var(--accent-foreground);
  --sidebar-border: var(--border);
  --sidebar-ring: var(--ring);

  --background: 160 100% 100%;
  --foreground: 30 6% 25%;
  --card: 160 50% 100%;
  --card-foreground: 160 5% 15%;
  --popover: 160 100% 100%;
  --popover-foreground: 160 100% 10%;
  --primary: 160 84.1% 39.4%;
  --primary-darker: 160 84.1% 29.4%;
  --primary-foreground: 0 0% 100%;
  --secondary: 160 30% 90%;
  --secondary-foreground: 0 0% 0%;
  --muted: 122 30% 95%;
  --muted-foreground: 160 5% 40%;
  --accent: 122 30% 90%;
  --accent-foreground: 160 5% 15%;
  --destructive: 0 100% 50%;
  --destructive-foreground: 160 5% 100%;
  --border: 160 30% 82%;
  --input: 160 30% 50%;
  --ring: 160 84.1% 39.4%;
  --radius: 0.5rem;

  --sidebar-background: 60 5% 96%;
  --sidebar-foreground: 240 5.3% 26.1%;
  --sidebar-primary: 240 5.9% 10%;
  --sidebar-primary-foreground: 0 0% 98%;
  --sidebar-accent: var(--accent);
  --sidebar-accent-foreground: var(--accent-foreground);
  --sidebar-border: var(--border);
  --sidebar-ring: var(--ring);
}

.theme_teal {
  --sidebar-background: 60 5% 96%;
  --sidebar-foreground: 240 5.3% 26.1%;
  --sidebar-primary: 240 5.9% 10%;
  --sidebar-primary-foreground: 0 0% 98%;
  --sidebar-accent: var(--accent);
  --sidebar-accent-foreground: var(--accent-foreground);
  --sidebar-border: var(--border);
  --sidebar-ring: var(--ring);

  --background: 173 100% 100%;
  --foreground: 30 6% 25%;
  --card: 173 50% 100%;
  --card-foreground: 173 5% 15%;
  --popover: 173 100% 100%;
  --popover-foreground: 173 100% 10%;
  --primary: 173 80.4% 40%;
  --primary-darker: 173 80.4% 30%;
  --primary-foreground: 0 0% 100%;
  --secondary: 173 30% 90%;
  --secondary-foreground: 0 0% 0%;
  --muted: 135 30% 95%;
  --muted-foreground: 173 5% 40%;
  --accent: 135 30% 90%;
  --accent-foreground: 173 5% 15%;
  --destructive: 0 100% 50%;
  --destructive-foreground: 173 5% 100%;
  --border: 173 30% 82%;
  --input: 173 30% 50%;
  --ring: 173 80.4% 40%;
  --radius: 0.5rem;
}

.theme_cyan {
  --sidebar-background: 60 5% 96%;
  --sidebar-foreground: 240 5.3% 26.1%;
  --sidebar-primary: 240 5.9% 10%;
  --sidebar-primary-foreground: 0 0% 98%;
  --sidebar-accent: var(--accent);
  --sidebar-accent-foreground: var(--accent-foreground);
  --sidebar-border: var(--border);
  --sidebar-ring: var(--ring);

  --background: 189 100% 100%;
  --foreground: 30 6% 25%;
  --card: 189 50% 100%;
  --card-foreground: 189 5% 15%;
  --popover: 189 100% 100%;
  --popover-foreground: 189 100% 10%;
  --primary: 189 94.5% 42.7%;
  --primary-darker: 189 94.5% 32.7%;
  --primary-foreground: 0 0% 100%;
  --secondary: 189 30% 90%;
  --secondary-foreground: 0 0% 0%;
  --muted: 151 30% 95%;
  --muted-foreground: 189 5% 40%;
  --accent: 151 30% 90%;
  --accent-foreground: 189 5% 15%;
  --destructive: 0 100% 50%;
  --destructive-foreground: 189 5% 100%;
  --border: 189 30% 82%;
  --input: 189 30% 50%;
  --ring: 189 94.5% 42.7%;
  --radius: 0.5rem;
}

.theme_sky {
  --sidebar-background: 60 5% 96%;
  --sidebar-foreground: 240 5.3% 26.1%;
  --sidebar-primary: 240 5.9% 10%;
  --sidebar-primary-foreground: 0 0% 98%;
  --sidebar-accent: var(--accent);
  --sidebar-accent-foreground: var(--accent-foreground);
  --sidebar-border: var(--border);
  --sidebar-ring: var(--ring);

  --background: 199 100% 100%;
  --foreground: 30 6% 25%;
  --card: 199 50% 100%;
  --card-foreground: 199 5% 15%;
  --popover: 199 100% 100%;
  --popover-foreground: 199 100% 10%;
  --primary: 199 88.7% 48.4%;
  --primary-darker: 199 88.7% 38.4%;
  --primary-foreground: 0 0% 100%;
  --secondary: 199 30% 90%;
  --secondary-foreground: 0 0% 0%;
  --muted: 161 30% 95%;
  --muted-foreground: 199 5% 40%;
  --accent: 161 30% 90%;
  --accent-foreground: 199 5% 15%;
  --destructive: 0 100% 50%;
  --destructive-foreground: 199 5% 100%;
  --border: 199 30% 82%;
  --input: 199 30% 50%;
  --ring: 199 88.7% 48.4%;
  --radius: 0.5rem;
}

.theme_blue {
  --sidebar-background: 60 5% 96%;
  --sidebar-foreground: 240 5.3% 26.1%;
  --sidebar-primary: 240 5.9% 10%;
  --sidebar-primary-foreground: 0 0% 98%;
  --sidebar-accent: var(--accent);
  --sidebar-accent-foreground: var(--accent-foreground);
  --sidebar-border: var(--border);
  --sidebar-ring: var(--ring);

  --background: 217 100% 100%;
  --foreground: 30 6% 25%;
  --card: 217 50% 100%;
  --card-foreground: 217 5% 15%;
  --popover: 217 100% 100%;
  --popover-foreground: 217 100% 10%;
  --primary: 217 91.2% 59.8%;
  --primary-darker: 217 91.2% 49.8%;
  --primary-foreground: 0 0% 100%;
  --secondary: 217 30% 90%;
  --secondary-foreground: 0 0% 0%;
  --muted: 179 30% 95%;
  --muted-foreground: 217 5% 40%;
  --accent: 179 30% 90%;
  --accent-foreground: 217 5% 15%;
  --destructive: 0 100% 50%;
  --destructive-foreground: 217 5% 100%;
  --border: 217 30% 82%;
  --input: 217 30% 50%;
  --ring: 217 91.2% 59.8%;
  --radius: 0.5rem;
}

.theme_indigo {
  --sidebar-background: 60 5% 96%;
  --sidebar-foreground: 240 5.3% 26.1%;
  --sidebar-primary: 240 5.9% 10%;
  --sidebar-primary-foreground: 0 0% 98%;
  --sidebar-accent: var(--accent);
  --sidebar-accent-foreground: var(--accent-foreground);
  --sidebar-border: var(--border);
  --sidebar-ring: var(--ring);

  --background: 239 100% 100%;
  --foreground: 30 6% 25%;
  --card: 239 50% 100%;
  --card-foreground: 239 5% 15%;
  --popover: 239 100% 100%;
  --popover-foreground: 239 100% 10%;
  --primary: 239 83.5% 66.7%;
  --primary-darker: 239 83.5% 56.7%;
  --primary-foreground: 0 0% 100%;
  --secondary: 239 30% 90%;
  --secondary-foreground: 0 0% 0%;
  --muted: 201 30% 95%;
  --muted-foreground: 239 5% 40%;
  --accent: 201 30% 90%;
  --accent-foreground: 239 5% 15%;
  --destructive: 0 100% 50%;
  --destructive-foreground: 239 5% 100%;
  --border: 239 30% 82%;
  --input: 239 30% 50%;
  --ring: 239 83.5% 66.7%;
  --radius: 0.5rem;
}

.theme_violet {
  --sidebar-background: 60 5% 96%;
  --sidebar-foreground: 240 5.3% 26.1%;
  --sidebar-primary: 240 5.9% 10%;
  --sidebar-primary-foreground: 0 0% 98%;
  --sidebar-accent: var(--accent);
  --sidebar-accent-foreground: var(--accent-foreground);
  --sidebar-border: var(--border);
  --sidebar-ring: var(--ring);

  --background: 258 100% 100%;
  --foreground: 30 6% 25%;
  --card: 258 50% 100%;
  --card-foreground: 258 5% 15%;
  --popover: 258 100% 100%;
  --popover-foreground: 258 100% 10%;
  --primary: 258 89.5% 66.3%;
  --primary-darker: 258 89.5% 56.3%;
  --primary-foreground: 0 0% 100%;
  --secondary: 220 14.3% 95.9%;
  --secondary-foreground: 220.9 39.3% 11%;
  --muted: 220 30% 95%;
  --muted-foreground: 258 5% 40%;
  --accent: 220 30% 90%;
  --accent-foreground: 258 5% 15%;
  --destructive: 0 100% 50%;
  --destructive-foreground: 258 5% 100%;
  --border: 258 30% 82%;
  --input: 258 30% 50%;
  --ring: 258 89.5% 66.3%;
  --radius: 0.5rem;
}

.theme_purple {
  --sidebar-background: 60 5% 96%;
  --sidebar-foreground: 240 5.3% 26.1%;
  --sidebar-primary: 240 5.9% 10%;
  --sidebar-primary-foreground: 0 0% 98%;
  --sidebar-accent: var(--accent);
  --sidebar-accent-foreground: var(--accent-foreground);
  --sidebar-border: var(--border);
  --sidebar-ring: var(--ring);

  --background: 271 100% 100%;
  --foreground: 30 6% 25%;
  --card: 271 50% 100%;
  --card-foreground: 271 5% 15%;
  --popover: 271 100% 100%;
  --popover-foreground: 271 100% 10%;
  --primary: 271 91% 65.1%;
  --primary-darker: 271 91% 55.1%;
  --primary-foreground: 0 0% 100%;
  --secondary: 271 30% 90%;
  --secondary-foreground: 0 0% 0%;
  --muted: 233 30% 95%;
  --muted-foreground: 271 5% 40%;
  --accent: 233 30% 90%;
  --accent-foreground: 271 5% 15%;
  --destructive: 0 100% 50%;
  --destructive-foreground: 271 5% 100%;
  --border: 271 30% 82%;
  --input: 271 30% 50%;
  --ring: 271 91% 65.1%;
  --radius: 0.5rem;
}

.theme_fuchsia {
  --sidebar-background: 60 5% 96%;
  --sidebar-foreground: 240 5.3% 26.1%;
  --sidebar-primary: 240 5.9% 10%;
  --sidebar-primary-foreground: 0 0% 98%;
  --sidebar-accent: var(--accent);
  --sidebar-accent-foreground: var(--accent-foreground);
  --sidebar-border: var(--border);
  --sidebar-ring: var(--ring);

  --background: 292 100% 100%;
  --foreground: 30 6% 25%;
  --card: 292 50% 100%;
  --card-foreground: 292 5% 15%;
  --popover: 292 100% 100%;
  --popover-foreground: 292 100% 10%;
  --primary: 292 84.1% 60.6%;
  --primary-darker: 292 84.1% 50.6%;
  --primary-foreground: 0 0% 100%;
  --secondary: 292 30% 90%;
  --secondary-foreground: 0 0% 0%;
  --muted: 254 30% 95%;
  --muted-foreground: 292 5% 40%;
  --accent: 254 30% 90%;
  --accent-foreground: 292 5% 15%;
  --destructive: 0 100% 50%;
  --destructive-foreground: 292 5% 100%;
  --border: 292 30% 82%;
  --input: 292 30% 50%;
  --ring: 292 84.1% 60.6%;
  --radius: 0.5rem;
}

.theme_pink {
  --sidebar-background: 60 5% 96%;
  --sidebar-foreground: 240 5.3% 26.1%;
  --sidebar-primary: 240 5.9% 10%;
  --sidebar-primary-foreground: 0 0% 98%;
  --sidebar-accent: var(--accent);
  --sidebar-accent-foreground: var(--accent-foreground);
  --sidebar-border: var(--border);
  --sidebar-ring: var(--ring);

  --background: 330 100% 100%;
  --foreground: 30 6% 25%;
  --card: 330 50% 100%;
  --card-foreground: 330 5% 15%;
  --popover: 330 100% 100%;
  --popover-foreground: 330 100% 10%;
  --primary: 330 81.2% 60.4%;
  --primary-darker: 330 81.2% 50.4%;
  --primary-foreground: 0 0% 100%;
  --secondary: 330 30% 90%;
  --secondary-foreground: 0 0% 0%;
  --muted: 292 30% 95%;
  --muted-foreground: 330 5% 40%;
  --accent: 292 30% 90%;
  --accent-foreground: 330 5% 15%;
  --destructive: 0 100% 50%;
  --destructive-foreground: 330 5% 100%;
  --border: 330 30% 82%;
  --input: 330 30% 50%;
  --ring: 330 81.2% 60.4%;
  --radius: 0.5rem;
}

.theme_rose {
  --sidebar-background: 60 5% 96%;
  --sidebar-foreground: 240 5.3% 26.1%;
  --sidebar-primary: 240 5.9% 10%;
  --sidebar-primary-foreground: 0 0% 98%;
  --sidebar-accent: var(--accent);
  --sidebar-accent-foreground: var(--accent-foreground);
  --sidebar-border: var(--border);
  --sidebar-ring: var(--ring);

  --background: 350 100% 100%;
  --foreground: 30 6% 25%;
  --card: 350 50% 100%;
  --card-foreground: 350 5% 15%;
  --popover: 350 100% 100%;
  --popover-foreground: 350 100% 10%;
  --primary: 350 89.2% 60.2%;
  --primary-foreground: 0 0% 100%;
  --secondary: 350 30% 90%;
  --secondary-foreground: 0 0% 0%;
  --muted: 312 30% 95%;
  --muted-foreground: 350 5% 40%;
  --accent: 312 30% 90%;
  --accent-foreground: 350 5% 15%;
  --destructive: 0 100% 50%;
  --destructive-foreground: 350 5% 100%;
  --border: 350 30% 82%;
  --input: 350 30% 50%;
  --ring: 350 89.2% 60.2%;
  --radius: 0.5rem;
}
